<template>
    <div>
        <div class="divider" style="margin-top: 0;margin-bottom: 5px;">
            <span style="color:#555;"><v-icon style="color:#555!important;">assignment_ind</v-icon>Our Community</span>
        </div>

        <div class="member-stat-bar">
            <ul>
                <li>
                    <div class="representation">
                        <v-icon>arrow_drop_down_circle</v-icon>
                    </div>
                    <div class="member-label" style="flex: 3.5">
                        <h3>Total Member</h3>
                        <p>Students, Guardian, Teacher and {{otherMembers}} Others</p>
                    </div>
                    <div class="counter">
                        <h2>{{allMemberCount}}</h2>
                    </div>
                </li>

                <li>
                    <div class="representation">
                        <img src="https://image.flaticon.com/icons/svg/201/201608.svg" alt="">
                    </div>
                    <div class="member-label">
                        <h3>Students</h3>
                        <p>Male {{memberCount.student.male}}, Female {{memberCount.student.female}}</p>
                    </div>
                    <div class="stats">
                        <p>{{memberCount.student.total || 0}}</p>
                        <v-progress-linear style="margin-top: 0;"
                                           :value="memberPercentageEvaluation(memberCount.student.total,'student')"></v-progress-linear>
                    </div>
                </li>


                <li>
                    <div class="representation">
                        <img src="https://image.flaticon.com/icons/svg/374/374971.svg" alt="">
                    </div>
                    <div class="member-label">
                        <h3>Guardian</h3>
                        <p>{{members.guardian.unassigned.count || 0}} Unassigned</p>
                    </div>
                    <div class="stats">
                        <p>{{memberCount.guardian}}</p>
                        <v-progress-linear style="margin-top: 0;"
                                           :value="memberPercentageEvaluation(memberCount.guardian,'guardian')"></v-progress-linear>
                    </div>
                </li>


                <li>
                    <div class="representation">
                        <img src="https://image.flaticon.com/icons/svg/1089/1089129.svg" alt="">
                    </div>
                    <div class="member-label">
                        <h3>Teachers</h3>
                        <p>Male {{memberCount.teacher.male}}, Female {{memberCount.teacher.female}}</p>
                    </div>

                    <div class="stats">
                        <p>{{memberCount.teacher.total || 0}}</p>
                        <v-progress-linear style="margin-top: 0;"
                                           :value="memberPercentageEvaluation(memberCount.teacher.total)"></v-progress-linear>
                    </div>
                </li>


            </ul>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        data() {
            return {
                memberCount: {
                    teacher: {
                        total: '',
                        male: '',
                        female: ''
                    },
                    student: {
                        total: '',
                        male: '',
                        female: ''
                    },
                    guardian: 0,
                    member: 0
                }
            }
        },
        computed: {
            ...mapState(['members']),

            allMemberCount() {
                return this.memberCount.student.total + this.memberCount.guardian + this.memberCount.member;
            },
            otherMembers(){
                return this.memberCount.member-this.memberCount.teacher.total;
            }
        },
        mounted() {
            this.memberCountCall();
        },
        methods: {
            memberPercentageEvaluation(count) {
                if (!count)
                    return 0;
                return (count * 100) / this.allMemberCount;
            },

            memberCountCall() {
                this.$rest.get('api/dashboard/gender-count').then(data => {
                    let studentTotal = 0;
                    let $this = this;
                    this.memberCount.member = data.data.member;
                    this.memberCount.guardian = data.data.guardian.guardian;
                    data.data.student.map(function (item) {
                        studentTotal += item.cnt;
                        if (item.gender === 'FEMALE')
                            $this.memberCount.student.female = item.cnt;

                        if (item.gender === 'MALE')
                            $this.memberCount.student.male = item.cnt;
                    });
                    this.memberCount.student.total = studentTotal;

                    let teacherTotal = 0;

                    data.data.teacher.map(function (item) {
                        teacherTotal += item.cnt;
                        if (item.gender === 'FEMALE')
                            $this.memberCount.teacher.female = item.cnt;
                        if (item.gender === 'MALE')
                            $this.memberCount.teacher.male = item.cnt;
                    });
                    this.memberCount.teacher.total = teacherTotal;
                });
            },
        }
    }
</script>